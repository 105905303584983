<template>
  <div class="singer-detail-side">
    <!-- <div class="singer-detail-side-title"></div> -->
    <div class="singer-detail-side-back" @click="$emit('back')">
      <img
        src="https://qncweb.ktvsky.com/20231218/vadd/675a88f8cb114a91342645d85f9f7b2f.png"
        alt=""
      />
      {{ singerData.name }}
    </div>
    <div class="close-side" @click="$emit('close')">
      <img
        src="https://qncweb.ktvsky.com/20231215/vadd/5965d997c5fb54308e509e10203cde56.png"
        alt=""
      />
    </div>
    <div class="singer-detail-side-main">
      <img
        class="fixed-header"
        v-if="singerHeaderIsfixed"
        :src="singerData.image"
        v-img-fallback="imgFallback"
      />
      <div class="list">
        <LoadMore
          class="song-list"
          ref="loadMoreRef"
          v-if="dataList.length"
          @load-more="fetchData"
          safeAreaHeight="11.6991vw"
        >
          <img
            class="header"
            :src="singerData.image"
            v-img-fallback="imgFallback"
          />
          <SongItem
            v-for="(songItem, index) in dataList"
            :key="index"
            :songItem="songItem"
            :log-from="{
              song_list_source: 12,
              fr2: '欢唱页歌手点歌会员歌曲',
            }"
            :singerEnable="false"
            :isStickButton="false"
          />
        </LoadMore>
        <div v-else-if="!isRequest" class="empty">暂无歌曲</div>
      </div>
    </div>
  </div>
</template>

<script>
import SongItem from '@/components/song-item/index.vue'
import { searchSinger } from '@/service/search'
import { onBeforeUnmount, onMounted, ref, toRefs } from 'vue'

export default {
  name: 'SingerDetail',
  props: {
    singerData: {
      default: () => {
        return {
          singerid: '',
          name: '',
          image: '',
        }
      },
      type: Object,
    },
  },
  components: {
    SongItem,
  },
  setup(props) {
    const { singerData } = toRefs(props)

    const imgFallback = {
      loading:
        'https://qncweb.ktvsky.com/20231226/vadd/a3056a76c9626aa4d68318625ae87a35.png',
      error:
        'https://qncweb.ktvsky.com/20231226/vadd/a3056a76c9626aa4d68318625ae87a35.png',
    }

    let p = 1
    let version = ref({
      current: '',
      latest: '',
    })
    let isRequest = ref(false)
    let dataList = ref([])
    let loadMoreRef = ref(null)
    let singerHeaderIsfixed = ref(false)

    const requestBussinessData = async () => {
      let responseData = []
      const { singerid } = singerData.value
      if (singerid) {
        const { data } = await searchSinger(singerid, p)
        responseData = {
          data: data.song,
        }
      }
      return responseData
    }

    const fetchData = async () => {
      if (isRequest.value) {
        return
      }
      isRequest.value = true

      const res = await requestBussinessData()
      if (res.data.length !== 0) {
        if (p === 1 && res.version) {
          version.value = res.version
        }
        dataList.value = dataList.value.concat(res.data)
        p++
      }
      isRequest.value = false
    }

    const handleScroll = (e) => {
      const scrollTop = e.target.scrollTop
      singerHeaderIsfixed.value = scrollTop > 210
    }

    onMounted(() => {
      // 请求数据
      fetchData()
      setTimeout(() => {
        if (loadMoreRef.value)
          loadMoreRef.value.root.addEventListener('scroll', handleScroll, false)
      }, 800)
    })

    onBeforeUnmount(() => {
      if (loadMoreRef.value)
        loadMoreRef.value.root.removeEventListener('scroll', handleScroll)
    })

    return {
      imgFallback,
      dataList,
      singerHeaderIsfixed,
      fetchData,
      loadMoreRef,
      isRequest,
    }
  },
}
</script>

<style lang="stylus" scoped>
.singer-detail-side
  width 100%
  height 100vh
  padding 0 48px
  position relative
  @media screen and (max-width 1200px)
    padding 0 60px
  ::-webkit-scrollbar
    display none
  &-title
    color rgba(255, 255, 255, 0.8)
    font-size 40px
    margin 64px 0 48px
    width 500px
    height 48px
    line-height 48px
    font-weight 600
  &-back
    color rgba(255, 255, 255, 0.8)
    font-size 32px
    font-weight 400
    display flex
    align-items center
    margin 63px 0 63px
    width 500px
    height 38px
    line-height 38px
    img
      width 36px
      height 36px
      margin-right 30px
    @media screen and (max-width 1200px)
      font-size 26px
      height 30px
      line-height 30px
      margin 48px 0 20px
      img
        width 26px
        height 26px
        margin-right 38px
  .close-side
    position absolute
    top 61px
    right 48px
    width 32px
    height 32px
    img
      width 100%
      height 100%
    @media screen and (max-width 1200px)
      top 48px
  &-main
    width 100%
    height 88vh
    display flex
    flex-direction column
    align-items center
    overflow-y scroll
    @keyframes fadeIn {
      from {
        opacity 0
      }
      to {
        opacity 1
      }
    }
    .visi-hidden
      visibility hidden
    .fixed-header
      position absolute
      top 52px
      left 50%
      margin-left -30px
      width 60px
      height 60px
      border-radius 50%
      animation fadeIn 1s ease-in-out
      @media screen and (max-width 1200px)
        top 28px
    .list
      width 100%
      ::-webkit-scrollbar
        display none
      // ::-webkit-scrollbar-track
      //   background #1E1F21
      .song-list
        width 100% !important
        padding 0 !important
        .header
          width 200px
          height 200px
          border-radius 50%
          margin 0 auto
          margin-bottom 48px
          padding 0 !important
        ::v-deep .song
          height 141px
        ::v-deep .song-item
          height 141px
          margin-top 0
          .right
            margin-right 0
        @media screen and (max-width 1200px)
          ::v-deep .song-item
            padding 0
            .name
              font-size 26px
            .desc
              font-size 20px
            .order-btn
              width 160px
              height 64px
              font-size 22px
</style>
