<template>
  <div
    class="operation-sus"
    :class="[
      route_page === 'search' && 'operation-sus-search',
      route_page === 'singer' && 'operation-sus-singer',
      route_page === 'home' && 'operation-sus-home',
      route_page === 'album' && 'operation-sus-album',
      route_page === 'songList' && 'operation-sus-songlist',
      route_page === 'mine' && 'operation-sus-mine',
      route_page === 'profile' && 'operation-sus-profile',
    ]"
    @click="handleShowVip"
  >
    <div @click.stop="$emit('close')" class="operation-sus-close"></div>
  </div>
</template>

<script>
import useVip from '@/composables/useVip'
import { sendLog } from '@/directives/v-log/log'
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default {
  name: 'OperationSus',
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const { showVipQrcode } = useVip()

    // 行为埋点 str1 event_name(二维码展示、支付码展示)
    const logEvents = {
      home: ['首页', 30120, 30121],
      search: ['搜索页/结果页', 30124, 30125],
      singing: ['唱过的歌页', 30128, 30129],
      singer: ['歌星列表页', 30132, 30133],
      songList: ['歌星详情页', 30136, 30137],
      mine: ['我的页', '', 30139],
      profile: ['会员中心页', '', 30141],
      vipExchange: ['兑换vip页', '', 30142],
    }

    // 搜索页和歌星页的来源埋点 - 车机端
    const frObj = {
      search: 1765,
      singer: 1767,
      songList: 1819,
      mine: 1825,
      profile: 1827,
      vipExchange: 1828,
    }

    const route_page = computed(() => route.name)
    const userInfo = computed(() => store.state.userInfo)
    const isLogin = computed(() => !!userInfo.value.unionid)

    const handleShowVip = () => {
      store.dispatch('vipAddSong/resetSong')
      // if (route_page.value === 'search') {
      //   sendLog({
      //     event_type: '10000~50000',
      //     event_name: 30143,
      //     event_data: {
      //       str1: '搜索结果页',
      //       str2: '底部运营位',
      //       str3: '底部运营位点击',
      //       str4: 'click',
      //     },
      //   })
      //   router.push({
      //     path: '/climax',
      //   })
      //   return
      // }
      sendLog({
        event_type: 'click',
        event_name: 1737,
      })
      // 此埋点先不区分页面 产品提供的埋点方案暂时没区分
      showVipQrcode({
        log: frObj[route.name] ? `${route.name}-底部运营位` : `首页-底部运营位`,
        isLogin: isLogin.value,
        fr: frObj[route.name] ? frObj[route.name] : 1759,
      })
      sendLog({
        event_type: 'show',
        event_name: 1738,
      })
      if (logEvents[route.name]) {
        if (isLogin.value) {
          sendLog({
            event_type: '10000~50000',
            event_name: logEvents[route.name][2],
            event_data: {
              str1: logEvents[route.name][0],
              str2: '底部运营位',
              str3: '支付码展示',
              str4: 'show',
            },
          })
        } else {
          sendLog({
            event_type: '10000~50000',
            event_name: logEvents[route.name][1],
            event_data: {
              str1: logEvents[route.name][0],
              str2: '底部运营位',
              str3: '二维码展示',
              str4: 'show',
            },
          })
        }
      }
    }

    return {
      route_page,
      handleShowVip,
    }
  },
}
</script>

<style lang="stylus" scoped>
.operation-sus
  // width 748px
  // height 80px
  // width 770px
  // height 90px
  width 886px
  height 116px
  display flex
  align-items center
  justify-content right
  position fixed
  bottom 118px
  left calc(50vw - 385px)
  // background url(https://qncweb.ktvsky.com/20240805/vadd/a2354c7be544d289d2a14f2310f423bc.png) no-repeat
  background url(https://qncweb.ktvsky.com/20240925/vadd/e61898e583d9fb28192b874d2c3841e2.png) no-repeat
  background-size 100% 100%
  background-position center
  z-index 0
  @media screen and (max-width: 1200px)
    // width 598px
    // height 64px
    // width 616px
    // height 72px
    width 708px
    height 92px
    left calc(50vw - 308px)
    // background url(https://qncweb.ktvsky.com/20240805/vadd/6d8d6fe3aac0b9be021c8c8c9eb6367b.png) no-repeat
    background url(https://qncweb.ktvsky.com/20240925/vadd/4b24512d898aef4b33f0ffa7be5bbd8d.png) no-repeat
    background-size 100% 100%
    background-position center
  &-close
    // width 30px
    width 40px
    height 100px
    margin-right 20px
  &.operation-sus-home
    bottom 20px !important
.operation-sus-search
  bottom 20px !important
//   width 678px
//   height 107px
//   background url(https://qncweb.ktvsky.com/20240411/other/9505b063e4ae953eb2c12929112f6d3d.png) no-repeat
//   background-size 100% 100%
// .operation-sus-singer
//   width 766px
//   height 141px
//   background url(https://qncweb.ktvsky.com/20231213/vadd/072ae7f281a657a5691b52c7b7c90d9a.png) no-repeat
//   background-size 100% 100%
.operation-sus-album
  // width 766px
  // height 141px
  // background url(https://qncweb.ktvsky.com/20240902/vadd/d7dca9b1f59836e6dd0ac1b4dfa508b8.png) no-repeat
  // background-size 100% 100%
  // background-position center
  bottom 20px !important
.operation-sus-singer
  // width 766px
  // height 141px
  // background url(https://qncweb.ktvsky.com/20240902/vadd/d7dca9b1f59836e6dd0ac1b4dfa508b8.png) no-repeat
  // background-size 100% 100%
  // background-position center
  bottom 20px !important
.operation-sus-songlist
  // width 766px
  // height 141px
  // background url(https://qncweb.ktvsky.com/20240902/vadd/d7dca9b1f59836e6dd0ac1b4dfa508b8.png) no-repeat
  // background-size 100% 100%
  // background-position center
  bottom 20px !important
.operation-sus-mine
  // width 766px
  // height 141px
  // background url(https://qncweb.ktvsky.com/20240902/vadd/d7dca9b1f59836e6dd0ac1b4dfa508b8.png) no-repeat
  // background-size 100% 100%
  // background-position center
  bottom 20px !important
.operation-sus-profile
  bottom 20px !important
</style>
