import { createStore } from 'vuex';
import uaInfo from '../utils/ua';
import store2 from 'store2';
import get from 'lodash/get';
import eventBus from '@/utils/event-bus'
import { getMac } from '@/service/device';
import { getCarplayInfo, getAIface } from '@/service/carplay-info';
import { getActivityInfo } from '@/service/activity'
import { loginout, resolutionRatio } from '@/service/user';
import { exchangeVip, checkOrderPayStatus } from '@/service/vip';
import { sendLog } from '@/directives/v-log/log'
import base from './modules/base'
import abTest from './modules/abTest'
import singTime from './modules/singTime'
import collect from './modules/collect'
import signin from './modules/signin'
import songLog from './modules/songLog'
import act from './modules/act'
import search from './modules/search';
import zerobuy from './modules/zerobuy'
import oftenSing from './modules/oftenSing'
import searchTips from './modules/searchTips'
import climax from './modules/climax'
import vipAddSong from './modules/vipAddSong'
import miniMv from './modules/miniMv'
import recommend from './modules/recommend'
import Toast from '@/utils/toast'
import router from '@/router'
import { getFreeVip } from '@/service/user';

let checkPayStatusTimer = 0
let checkLoginStatusTimer = 0
let checkLoginKeepStatusTimer = 0
let checkVipInfoStatusTimer = 0
let checkAIfaceTimer = 0
let firstTime = true

export default createStore({
  state: {
    controlFromType: 1, // "点歌类型：1：点歌屏操作，2：按键操作，3：扫描点歌, 4: 首页会员优化(特斯拉)"
    loginModalEnabled: false,
    isForceLogin: false,
    sing_tips: { // 云控提示
      duration: 6, // 默认提示内容显示6s
      img: 'https://qncweb.ktvsky.com/20231024/other/5b9ee37d2990e0ca7f64c18b929e3472.png', // 提示框的云控背景
      songs: 4, // 加入已点歌曲数量，当达到此数量时进行显示
      text: '提示您：已点{songs}首，立即欢唱吧~', // 提示框的文案
    },
    systemInfo: {
      brand: '',
      model: '',
      system: ''
    },
    macAddress: '',
    alreadyList: [],
    orderedList: [],
    orderedSongIdMap: {},
    carplayInfo: {},
    videoPlayerHistory: {
      songItem: {
        acc: 0,
        flag: [],
        music_name: '',
        org: 0,
        singer: '',
        songid: 0,
        m3u8: '',
        hls: '',
        token: '', // 新增token播放鉴权
        tokenExp: 300, // token过期时间
        serverTime: 0,
        lrc: 0, // 歌词类型 0-5 0为无歌词，其他为有歌词
        lrcData: {
          is_utf8: 1, // 是否是utf8格式文件 0:false 1:true
          down_url: '', // 歌词下载文件地址
        },
      },
      songItemLrc: [],
      defaultAccTrackOrder: 1,
      audioTrackMap: {
        acc: {},
        org: {}
      },
      currentTime: 0,
      enabledAudioTrack: {
        id: 999 // 未决断出音轨id之前 使用999 表示同类型未知
      },
      recovery: false
    },
    setting: {
      quality: '480',
    },
    lowerBeforeQ: '',
    userInfo: {
      username: '',
      avatar: '',
      unionid: '',
      openid: '',
      phone: '',
      userType: 1
    },
    hasMic: undefined, // 初始化时置为undefined
    activityInfo: {
      loginSendVip: undefined, // 初始化时置为undefined
      openscreen_vip: {
        id: undefined,
        img: ''
      },
    },
    vipInfo: {
      expire: false, // 用户是否是过期会员
      end_time: '',
      vip_imgs: []
    },
    mvIsHide: true,
    videoPaused: true,
    videoVolume: 50,
    playingMvQuality: 720, // 当前正播放歌曲的画质
    videoInitAutoPlay: 0, // 0 - 初次尚未进入过mv页， 1 - 检测到字段变化为1后需要自动播放
    currIrcIndex: -1, // 当前播放歌词索引， -1 代表未开始播放歌词
    isSingStatus: false, // 是否进入过mv演唱界面
    pageCacheData: { // 页面keep-alive处理
      classify: {
        isUsed: false,
        position: 0
      },
      singer: {
        isUsed: false,
        position: 0
      },
      playlist: {
        isUsed: false,
        position: 0
      },
      search: {
        isUsed: false,
        position: 0
      },
    },
    appStartTime: 0,
    aiFaceSong: [],
    freeVipNumber: 0,
    isFirstSong: false,//是否点过歌
    aiFaceQr: '',
    isShowXifen: false,
    subscribe_gzh: {
      count: 0,
    }
  },
  mutations: {
    RESET_VIDEO_PLAYER(state) {
      state.videoPlayerHistory = {
        songItem: {
          acc: 0,
          flag: [],
          music_name: '',
          org: 0,
          singer: '',
          songid: 0,
          m3u8: '',
          hls: '',
          token: '', // 新增token播放鉴权
          tokenExp: 300, // token过期时间
          serverTime: 0,
          lrc: 0,
          lrcData: {
            is_utf8: 1, // 是否是utf8格式文件 0:false 1:true
            down_url: '', // 歌词下载文件地址
          },
        },
        songItemLrc: [],
        defaultAccTrackOrder: 1,
        audioTrackMap: {
          acc: {},
          org: {}
        },
        currentTime: 0,
        enabledAudioTrack: {
          id: 999
        },
        recovery: false,
        isAIMV: false,
      }
    },
    RESET_VIDEO_PLAYER_HLS(state) {
      state.videoPlayerHistory.songItem.hls = ''
    },
    SAVE_LOGINMODAL_ENABLED(state, enabled) {
      state.loginModalEnabled = enabled;
    },
    SAVE_MAC_ADDRESS(state, macAddress) {
      state.macAddress = macAddress;
    },
    SAVE_SYSTEM_INFO(state, systemInfo) {
      state.systemInfo = systemInfo;
    },
    SAVE_ORDERED_LIST(state, orderedList) {
      state.orderedList = orderedList;
    },
    SAVE_ALREADY_LIST(state, alreadyList) {
      state.alreadyList = alreadyList;
    },
    SAVE_ORDERED_SONGIDMAP(state, id) {
      state.orderedSongIdMap[id] = true
    },
    PUSH_SONG_TO_ALREADY_LIST(state, song) {
      state.alreadyList.unshift(song)
    },
    DELETE_SONG_ALREADY_LIST(state, index) {
      state.alreadyList.splice(index, 1)
    },
    PUSH_SONG_TO_ORDERED_LIST(state, { song, from }) {
      state.isFirstSong = true;
      // console.log('加入已点', from)
      const logFrom = get(from, 'song_list_source', false) ? from : {}
      // 过滤不是已知来源的点歌
      if (Object.keys(logFrom).length) {
        sendLog({
          event_type: 'click',
          event_name: 121,
          event_data: {
            ...logFrom,
            type: state.controlFromType,
            song_id: song.songid,
            song_name: song.music_name,
            singer: song.singer,
            song_isvip: song.is_vip, // 增加歌曲vip上报标识
            str3: state.userInfo.userType,
          }
        })
      }
      state.orderedList.push(song)
      // 同步存储已点map
      state.orderedSongIdMap[song.songid] = true
    },
    SHIFT_SONG_ORDERED_LIST(state) {
      const _shiftSong = state.orderedList.shift()
      delete state.orderedSongIdMap[_shiftSong.songid]
    },
    UNSHIFT_SONG_ORDERED_LIST(state, data) {
      state.orderedList.unshift(data)
      state.orderedSongIdMap[data.songid] = true
    },
    DELETE_SONG_ORDER_LIST(state, index) {
      const deletedItem = get(state.orderedList.splice(index, 1), '0', null)
      if (deletedItem) {
        delete state.orderedSongIdMap[deletedItem.songid]
      }
    },
    STICK_SONG_TO_TOP_ORDERED_LIST(state, index) {
      const deletedItem = get(state.orderedList.splice(index, 1), '0', null)
      if (deletedItem) {
        // 在第二位加入这首歌
        state.orderedList.splice(1, 0, deletedItem)
      }
    },
    SAVE_VIDEO_PLAYER_HISTORY(state, videoPlayerHistory) {
      state.videoPlayerHistory = videoPlayerHistory
    },
    SAVE_VIDEO_PLAYER_HISTORY_SONG_ITEM(state, songItem) {
      // m3u8资源单独作为一个接口后 这里需要处理为合并而不是覆盖 防止覆盖掉本因存在的hls资源
      state.videoPlayerHistory.songItem = {
        ...state.videoPlayerHistory.songItem,
        ...songItem,
        isAIMV: songItem.isAIMV,
      }
    },
    SAVE_VIDEO_PLAYER_HISTORY_AUDIO_TRACKS(state, audioTrackMap) {
      state.videoPlayerHistory.audioTrackMap = audioTrackMap
    },
    SAVE_VIDEO_PLAYER_HISTORY_CURRENT_TIME(state, currentTime) {
      state.videoPlayerHistory.currentTime = currentTime || 0
    },
    SAVE_VIDEO_PLAYER_HISTORY_ENABLED_AUDIO_TRACK(state, enabledAudioTrack) {
      state.videoPlayerHistory.enabledAudioTrack = enabledAudioTrack
    },
    SAVE_VIDEO_PLAYER_HISTORY_DEFAULT_ACC_TRACK_ORDER(state, defaultAccTrackOrder) {
      console.log('defaultAccTrackOrder', defaultAccTrackOrder)
      state.videoPlayerHistory.defaultAccTrackOrder = defaultAccTrackOrder
    },
    UPDATE_VIDEO_PLAYER_RECOVERY(state, recovery) {
      state.videoPlayerHistory.recovery = recovery
    },
    SAVE_SETTING(state, setting) {
      state.setting = setting
    },
    CHANGE_PLAYING_MV_QUALITY(state, val) {
      state.playingMvQuality = val
    },
    CHANGE_PLAYING_MV_QUALITY_RESOURCE(state, hls) {
      state.videoPlayerHistory.songItem.hls = hls
    },
    SAVE_CARPLAY_INFO(state, carplayInfo) {
      state.carplayInfo = carplayInfo
    },
    SAVE_USER_INFO(state, userInfo) {
      state.userInfo = {
        ...state.userInfo,
        ...userInfo,
      }
    },
    SAVE_HAS_MIC(state, data) {
      state.hasMic = data.hasMic
    },
    SAVE_AI_FACE_QR(state, data) {
      state.aiFaceQr = data.aiface_qr
    },
    SAVE_ACTIVITY_INFO(state, activity) {
      state.activityInfo = {
        ...state.activityInfo,
        ...activity,
      }
    },
    SAVE_AUTH_INFO(state, authInfo) {
      state.userInfo = {
        ...state.userInfo,
        ...authInfo,
      }
    },
    SAVE_VIP_INFO(state, vipInfo) {
      state.vipInfo.end_time = vipInfo.end_time || ''
      state.vipInfo.vip_imgs = vipInfo.vip_imgs || []
      state.vipInfo.expire = vipInfo.expire || false
    },
    SAVE_IS_FORCE_LOGIN(state, data) {
      state.isForceLogin = data.isForceLogin
    },
    SAVE_SING_TIPS(state, data) {
      state.sing_tips = data
    },
    UPDATE_MV_ISHIDE(state, isHide) {
      state.mvIsHide = isHide
    },
    UPDATE_MV_VIDEO_PAUSE(state, videoPaused) {
      state.videoPaused = videoPaused
    },
    UPDATE_MV_VIDEO_VOLUME(state, volume) {
      state.videoVolume = volume
    },
    UPDATE_MV_INIT_AUTOPLAY(state, num) {
      state.videoInitAutoPlay = num
    },
    UPDATE_IS_SING_STATUS(state, bol) {
      state.isSingStatus = bol
    },
    UPDATE_PAGE_CACHEDATA(state, payload) {
      const { data, type } = payload
      state.pageCacheData[type] = { ...state.pageCacheData[type], ...data }
    },
    UPDATE_CURR_SONG_HLS(state, data) {
      state.videoPlayerHistory.songItem.hls = data.hls
      state.videoPlayerHistory.songItem.token = data.token
      state.videoPlayerHistory.songItem.tokenExp = data.tokenExp
      state.videoPlayerHistory.songItem.m3u8 = data.m3u8
      state.videoPlayerHistory.songItem.serverTime = data.serverTime
      state.videoPlayerHistory.songItem.lrcData = data.lrc
      for (let q in data.m3u8) {
        if (data.hls === data.m3u8[q]) {
          state.playingMvQuality = q
          // 更新本地及云端数据
          state.setting = {
            ...state.setting,
            quality: q
          }
          store2('setting', state.setting)
          if (state.userInfo.unionid && state.carplayInfo.resolution !== q) {
            resolutionRatio({ unionid: state.userInfo.unionid, quality: q})
          }
        }
      }
    },
    UPDATE_CURR_SONG_TOKEN(state, data) {
      state.videoPlayerHistory.songItem.token = data.token
      state.videoPlayerHistory.songItem.tokenExp = data.tokenExp
      state.videoPlayerHistory.songItem.serverTime = data.serverTime
    },
    UPDATE_APP_START_TIME(state, data) {
      state.appStartTime = data
    },
    UPDATE_CONTROL_FROM_TYPE(state, data) {
      console.log('UPDATE_CONTROL_FROM_TYPE', data)
      state.controlFromType = data
    },
    RESET_CURR_SONG_LRC(state) {
      state.videoPlayerHistory.songItemLrc = []
    },
    UPDATE_CURR_SONG_LRC(state, data) {
      state.videoPlayerHistory.songItemLrc = data.lrc
    },
    UPDATE_CURR_IYRIC_INDEX(state, data) {
      state.currIrcIndex = data
    },
    UPDATE_LOWERBEFOREQ(state, data) {
      state.lowerBeforeQ = data
    },
    ADD_AI_FACE(state, data) {
      state.aiFaceSong = state.aiFaceSong.concat(data)
      store2('aiFaceSong', state.aiFaceSong)
    },
    SHIFT_AI_FACE(state, data) {
      if (state.aiFaceSong.length) {
        if (state.aiFaceSong.length === 1) {
          router.push('/')
        }
        state.aiFaceSong.shift()
        store2('aiFaceSong', state.aiFaceSong)
      }
    },
    SHIFT_AND_OR_AI_ORDERED_LIST(state, index) {
      console.log('SHIFT_AND_OR_AI_ORDERED_LIST')
      if (state.aiFaceSong.length) {
        if (index > 1) {
          const deletedItem = get(state.aiFaceSong.splice(index, 1), '0', null)
          if (deletedItem) {
            // 在第二位加入这首歌
            state.aiFaceSong.splice(1, 0, deletedItem)
          }
        }
        state.aiFaceSong.shift()
        store2('aiFaceSong', state.aiFaceSong)
      }
    },
    STICK_SONG_TO_TOP_AI_ORDERED_LIST(state, index) {
      const deletedItem = get(state.aiFaceSong.splice(index, 1), '0', null)
      if (deletedItem) {
        // 在第二位加入这首歌
        state.aiFaceSong.splice(1, 0, deletedItem)
      }
      store2('aiFaceSong', state.aiFaceSong)
    },
    DELETE_AI_ORDER_LIST(state, index) {
      const deletedItem = get(state.aiFaceSong.splice(index, 1), '0', null)
    },
    INIT_AI_FACE(state, data) {
      const res = store2('aiFaceSong')
      if (res && res.length) {
        state.aiFaceSong = res
      }
    },
    SAVE_FREE_VIP_COUNT(state, data) {
      if (typeof data === 'number') {
        // 如果 data 是数字 0
        state.freeVipNumber = data;
        state.freeVipNumber1 = data;
      } else if (typeof data === 'object' && data !== null) {
        // 如果 data 是对象
        state.freeVipNumber = data.free_vip_count ?? data.subscribe_gzh?.count;
        state.freeVipNumber1 = data.free_vip_count;
      }
    },
    SAVE_FREE_VIP_XIFEN(state, payload) {
      state.isShowXifen = !payload.is_used && !payload.is_subscribe;
      state.subscribe_gzh = payload;
    }
  },
  actions: {
    async fetchFreeVip({ commit, state }) {
      if (!state.userInfo.unionid) return
      
      const res = await getFreeVip({
        unionid: state.userInfo.unionid,
      });
      commit('SAVE_FREE_VIP_COUNT', res.data);
      commit('SAVE_FREE_VIP_XIFEN', res.data.subscribe_gzh);
    },
    startVipInfoStatus({ dispatch }, payload = {}) {
      const { stopPolling } = payload;
      function checkVipInfoStatus() {
        if (stopPolling) {
          clearTimeout(checkVipInfoStatusTimer);
          return;
        }
        if (checkVipInfoStatusTimer) {
          clearTimeout(checkVipInfoStatusTimer);
        }
        dispatch('getCarplayInfo');
        checkVipInfoStatusTimer = setTimeout(checkVipInfoStatus, 2 * 1000);
      }

      checkVipInfoStatus();
    },
    startCheckLoginKeepStatus({ dispatch }) {
      function checkLoginKeepStatus() {
        if (checkLoginKeepStatusTimer) {
          clearTimeout(checkLoginKeepStatusTimer)
        }
        dispatch('getCarplayInfo')
        checkLoginKeepStatusTimer = setTimeout(checkLoginKeepStatus, 60 * 1000)
      }
      checkLoginKeepStatus()
    },
    async getAIface({ commit, state }) {
      try {
        const { userInfo, orderedList } = state;
        const { data = {} } = await getAIface(userInfo.unionid);
        console.log(data.data_list, 'getAIface')
        let hintStr = 'MV自制完成，已加入已点列表'
        if (orderedList.length === 0) {
          hintStr = 'MV自制完成，已为您自动播放'
        }
        if (data.data_list.length > 0) {
          data.data_list.forEach((songData) => {
            // aiFaceId
            // headimgurl
            // name
            // nickname
            // singer
            // url
            // songData.url = 'http://faces.ktvsky.com/faceswap/out/2024/05/10/10000048/10000048_index.m3u8'
            if (songData.url.includes('.mp4')) return
            let _song = {
              acc: 0,
              flag: [],
              music_name: `${songData.name}`, // -${songData.nickname}
              org: 0,
              singer: songData.singer,
              singerid: songData.singerid,
              aiFaceId: songData.aiFaceId,
              songid: songData.songid,
              singer_head: songData.singer_head,
              m3u8: {
                480: songData.url,
                720: songData.url,
                1080: songData.url,
              },
              hls: songData.url,
              token: '', // 新增token播放鉴权
              tokenExp: 300, // token过期时间
              serverTime: 0,
              is_vip: false,
              isAIMV: true,
              unionid: songData.unionid
            }
            commit('PUSH_SONG_TO_ORDERED_LIST', { song: _song, from: {}})
            if (orderedList.length === 1) {
              commit('SAVE_VIDEO_PLAYER_HISTORY_SONG_ITEM', _song)
              commit('UPDATE_MV_ISHIDE', false)
              setTimeout(() => {
                eventBus.emit('handle-video-play')
              }, 3500)
            }
            if (orderedList.length > 2) {
              // stick top
              commit('STICK_SONG_TO_TOP_ORDERED_LIST', orderedList.length - 1)
            }
            Toast({
              message: hintStr,
              position: 'center',
              className: 'toast-zoom-max'
            })
          })
          // 先不去重
          // commit('ADD_AI_FACE', data.data_list);
          // router.push({ name: 'aiface', query: { from: router.currentRoute.value.name } });
        }
      } catch (error) {
        console.error('Error fetching AI face data:', error);
        // Handle error appropriately, such as showing a message to the user
      }
    },
    startCheckAIface({ dispatch }) {
      const checkAIface = () => {
        if (checkAIfaceTimer) {
          clearTimeout(checkAIfaceTimer)
        }
        dispatch('getAIface')
        checkAIfaceTimer = setTimeout(checkAIface, 60 * 1000 * 3)
      }

      checkAIface()
    },
    // TODO 先这么写，不太对
    async startCheckPayStatus({ dispatch }, { order_id }) {
      let checkPayStatusLimit = 0
      async function checkPayStatus() {
        if (checkPayStatusTimer) {
          clearTimeout(checkPayStatusTimer)
        }
        if (checkPayStatusLimit >= 20) {
          checkPayStatusLimit = 0
          return
        }
        checkPayStatusLimit++
        const res = await checkOrderPayStatus(order_id)
        if (get(res, 'errmsg') !== '支付成功') {
          checkPayStatusTimer = setTimeout(checkPayStatus, 5000)
        } else {
          dispatch('getCarplayInfo')
        }
      }
      checkPayStatus()
    },
    setLoginModalEnabled({ commit }, enabled) {
      commit('SAVE_LOGINMODAL_ENABLED', enabled)
    },
    async loginout({ dispatch }, { mac_id, unionid }) {
      const res = await loginout({
        mac_id,
        unionid,
      })
      if (get(res, 'errcode') == 200) {
        dispatch('getCarplayInfo')
        // 埋点224
        sendLog({
          event_type: 'click',
          event_name: 224,
          event_data: {
            login_status: 1,
            login_msg: get(res, 'errmsg')
          }
        })
      } else {
        // 埋点224
        sendLog({
          event_type: 'click',
          event_name: 224,
          event_data: {
            login_status: 2,
            login_msg: get(res, 'errmsg')
          }
        })
      }
    },
    // TODO 先这么写，不太对
    startCheckLoginStatus({ dispatch, state }) {
      let time = 0
      function checkLoginStatus() {
        if (checkLoginStatusTimer) {
          clearTimeout(checkLoginStatusTimer)
        }
        if (time >= 20) {
          time = 0
          return
        }
        time++
        if (!get(state, 'carplayInfo.user.unionid')) {
          dispatch('getCarplayInfo')
          checkLoginStatusTimer = setTimeout(checkLoginStatus, 3000)
        }
      }
      checkLoginStatus()
    },
    async exchangeVip({ commit }, { cdkey, mac_id }) {
      const exchangeVipRes = await exchangeVip({
        cdkey,
        mac_id
      })
      if (exchangeVipRes.data) {
        commit('SAVE_VIP_INFO', exchangeVipRes.data)
        return exchangeVipRes
      }
    },
    getMacAddress({ commit }) {
      const mac = getMac()
      commit('SAVE_MAC_ADDRESS', mac)
    },
    getSystemInfo({ commit }) {
      commit('SAVE_SYSTEM_INFO', {
        brand: uaInfo.browser.name,
        model: uaInfo.device.model || uaInfo.browser.name,
        system: uaInfo.os.name,
      })
    },
    async getCarplayInfo({ commit, dispatch, state }) {
      try {
        const { data } = await getCarplayInfo()
        if (data) {
          if (!firstTime && state.userInfo.unionid && data.vip_info.end_time && data.vip_info.end_time !== state.vipInfo.end_time) {
            Toast('VIP开通成功')
          }
          firstTime = false
          commit('SAVE_CARPLAY_INFO', data)
          if (get(data, 'user.unionid', '')) {
            dispatch('setLoginModalEnabled', false)
          }
          commit('SAVE_IS_FORCE_LOGIN', {
            isForceLogin: get(data, 'isForceLogin', false) // 0：不启用 1：启用
          })

          let userType = null
          if (typeof data.vip_info.end_time === 'undefined') {
            userType = 1
          }
          if (data.vip_info.end_time && data.has_mic) {
            userType = 3
          }
          if (data.vip_info.end_time && data.has_order) {
            userType = 2
          }
          if (data.vip_info.expire) {
            userType = 4
          }

          commit('SAVE_USER_INFO', {
            username: get(data, 'user.nickname', ''),
            avatar: get(data, 'user.headimgurl', ''),
            unionid: get(data, 'user.unionid', ''),
            openid: get(data, 'user.openid', ''),
            phone: get(data, 'user.phone', ''),
            userType,
          })
          commit('SAVE_HAS_MIC', {
            hasMic: get(data, 'has_mic', false),
          })
          commit('SAVE_AI_FACE_QR', {
            aiface_qr: get(data, 'aiface_qr', ''),
          })
          commit('SAVE_ACTIVITY_INFO', {
            loginSendVip: get(data, 'event.free_vip', ''),
          })
          commit('SAVE_VIP_INFO', {
            vip_imgs: data.vip_imgs,
            end_time: data.vip_info.end_time,
            expire: data.vip_info.expire,
          })

          commit('SAVE_SING_TIPS', {
            duration: get(data, 'sing_tips.duration', 6), // 默认提示内容显示6s
            img: get(data, 'sing_tips.img', 'https://qncweb.ktvsky.com/20231024/other/5b9ee37d2990e0ca7f64c18b929e3472.png'), // 提示框的云控背景
            songs: get(data, 'sing_tips.songs', 4), // 加入已点歌曲数量，当达到此数量时进行显示
            text: get(data, 'sing_tips.text', '提示您：已点{songs}首，立即欢唱吧~'), // 提示框的文案
          })
        }

        // watch(state.carplayInfo, (newVal, oldVal) => {
        //   if (newVal.login_qr !== oldVal.login_qr || newVal.pay_qr !== oldVal.pay_qr) {
        //     const { setRetainModalTag } = useRetainModal()
        //     setRetainModalTag()
        //   }
        // })
      } catch (error) {
        console.log('接口请求错误')
      }
    },
    saveSetting({ commit }, setting) {
      store2('setting', setting)
      commit('SAVE_SETTING', setting)
    },
    getSetting({ commit }) {
      let setting = store2('setting')
      if (!setting) {
        setting = this.state.setting
      }
      commit('SAVE_SETTING', setting)
      console.log('启动应用，获取本地画质', setting.quality)
      commit('CHANGE_PLAYING_MV_QUALITY', setting.quality)
    },
    async setActivityInfo({ commit }) {
      try {
        const data = await getActivityInfo()
        if (get(data, 'tp') == 10) {
          // vip二维码弹窗
          commit('SAVE_ACTIVITY_INFO', {
            openscreen_vip: {
              id: get(data, 'id', false),
              img: get(data, 'imgurl', ''),
            },
          })
        } else {
          // 空值
          commit('SAVE_ACTIVITY_INFO', {
            openscreen_vip: {
              id: false,
              img: '',
            },
          })
          if (get(data, 'tp') == 12) {
            // 歌曲推荐弹窗
            console.log('12')
          }
        }
      } catch (err) {
        console.log('接口请求错误')
      }
    },
    orderSongType({ state }, data) {
      data.forEach((item) => {
        const curIndex = state.orderedList.findIndex((data) => {
          return item.songid === data.songid
        })
        if (curIndex > -1) {
          item.type = 'order'
        } else {
          delete item.type
        }
      })
    }
  },
  modules: {
    base,
    abTest,
    singTime,
    collect,
    signin,
    songLog,
    act,
    search,
    zerobuy,
    oftenSing,
    searchTips,
    climax,
    vipAddSong,
    miniMv,
    recommend,
  }
});
