<template>
  <div class="mv-quality-side">
    <div class="mv-quality-side-title">
      <div class="left">画质选择</div>
      <img
        class="close"
        src="https://qncweb.ktvsky.com/20231212/other/f3e0b3ce8cd5bbc595aa74a5199aa046.svg"
        @click="$emit('close')"
      />
    </div>
    <div class="mv-quality-side-main">
      <div
        class="mv-quality-side-main-item"
        :class="[
          playingMvQuality === quality.value &&
            'mv-quality-side-main-item-active',
          !playingSongItemM3u8[quality.value] &&
            'mv-quality-side-main-item-unactive',
        ]"
        v-for="(quality, index) in qualityList"
        :key="index"
        @click="handleChoose(quality)"
      >
        <p class="title">{{ quality.name }}</p>
        <p class="des">
          {{ quality.label }}
          <img
            v-if="quality.value === '1080'"
            src="https://qncweb.ktvsky.com/20230830/vadd/c6f52232a92ac7d2273b244a5d6daf05.png"
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import useQuality from '@/composables/useQuality'
import useVip from '@/composables/useVip'
import { sendLog } from '@/directives/v-log/log'
import eventBus from '@/utils/event-bus'
import get from 'lodash/get'
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'MvQualityModal',
  props: {},
  setup() {
    const store = useStore()
    const { showVipQrcode } = useVip()
    const { useMvQuality } = useQuality()
    const isLogin = computed(() => !!store.state.userInfo.unionid)
    const isVip = computed(() => !!store.state.vipInfo.end_time)
    const playingMvQuality = computed(() => store.state.playingMvQuality)
    const playingSongItemM3u8 = computed(() =>
      get(store.state.videoPlayerHistory, 'songItem.m3u8', {})
    )
    const videoPlayer = computed(() => store.state.videoPlayerHistory)
    const userType = computed(() => store.state.userInfo.userType)

    const qualityList = [
      {
        name: '480P',
        label: '标清',
        value: '480',
      },
      {
        name: '720P',
        label: '高清',
        value: '720',
      },
      {
        name: '1080P',
        label: '蓝光',
        value: '1080',
      },
    ]

    const qualityLog = {
      480: [30112, '480p'],
      720: [30113, '720p'],
      1080: [30114, '1080p'],
    }

    const handleChoose = (quality) => {
      if (playingMvQuality.value === quality.value) return
      if (qualityLog[quality.value]) {
        sendLog({
          event_type: '10000~50000',
          event_name: qualityLog[quality.value][0],
          event_data: {
            str1: '欢唱页',
            str2: '画质选择',
            str3: qualityLog[quality.value][1],
            str4: 'click',
            str5: isLogin.value ? '已登录' : '未登录',
            str7: userType.value,
          },
        })
      }
      if (quality.value === '1080' && (!isLogin.value || !isVip.value)) {
        showVipQrcode({
          mType: 'mv页画质vip弹窗',
          log: '画质切换-1080',
          fromType: 'vipHighQuality',
        })
        return
      }

      eventBus.emit('video-quality-change')
      useMvQuality(quality.value)
      sendLog({
        event_type: 'custom',
        event_name: 1070,
        event_data: {
          str1: '歌曲清晰度埋点',
          str2: videoPlayer.value.songItem.songid,
          str3: videoPlayer.value.songItem.music_name,
          str4: videoPlayer.value.songItem.singer,
          str5: `${quality.value}P`, // 画质
          str6: '2', // 上报时机
        },
      })
    }

    return {
      qualityList,
      playingMvQuality,
      playingSongItemM3u8,
      handleChoose,
    }
  },
}
</script>

<style lang="stylus" scoped>
.mv-quality-side
  width 100%
  height 100vh
  padding 0 48px
  position relative
  &-title
    display flex
    justify-content space-between
    align-items center
    margin 22px 0 60px
    .left
      display flex
      align-items center
      height 120px
      color: rgba(255, 255, 255, 0.80);
      font-size: 32px;
    .close
      width 25px
  @media screen and (max-width 1200px) and (min-height 1200px)
    &-title
      margin 0
      .left
        font-size 26px
  &-main
    width 100%
    height auto
    display flex
    flex-direction column
    align-items center
    &-item
      width 704px
      height 180px
      margin-bottom 36px
      display flex
      flex-direction column
      align-items center
      justify-content center
      border-radius 10px
      background: linear-gradient(94deg, rgba(56, 58, 62, 0.90) 0.37%, rgba(56, 58, 62, 0.50) 98.8%);
      position relative
      .title
        color rgba(255, 255, 255, 0.8)
        font-size 56px
        font-weight 500
        height 66px
        margin-bottom 12px
      .des
        color rgba(255, 255, 255, 0.5)
        font-size 28px
        display flex
        align-items center
        justify-content center
        font-weight 400
        img
          width 56px
          height 28px
          margin-left 12px
      &-active
        border: 4px solid #E3AB5D;
        background: rgba(228, 172, 94, 0.10);
      &-unactive
        display none
        .title
          color rgba(255, 255, 255, 0.10) !important
        .des
          color rgba(255, 255, 255, 0.10) !important
    @media screen and (max-width 1200px) and (min-height 1200px)
      flex-direction row
      margin-top 16px
      &-item
        width 340px
        height 200px
        margin 0
        .title
          font-size 44px
        .des
          font-size 22px
        &:nth-child(2)
          margin 0 30px
</style>
