<template>
  <CommonModal ref="root" :onCancel="handleCancel">
    <div class="vip-modal-content" :class="fromType">
      <div @click="handleCloseModal" class="close"></div>
      <div class="qrcode">
        <img :src="qrCodeURL" />
      </div>
    </div>
  </CommonModal>
</template>
<script>
import CommonModal from '@/components/modal/common/component.vue'
import useQRCode from '@/composables/useQRCode'
import useRetainModal from '@/composables/useRetainModal'
import { vipLogFrom } from '@/constants/index'
import { sendLog } from '@/directives/v-log/log'
import { payLogFrom } from '@/log/pay'
import { getCarplayInfo } from '@/service/carplay-info'
import store from '@/store'
import Toast from '@/utils/toast'
import get from 'lodash/get'
import { computed, onBeforeMount, ref, toRefs } from 'vue'

export default {
  name: 'VipModalQRcode',
  components: {
    CommonModal,
  },
  props: {
    songid: {
      type: Number,
      default: 0, // 当通过点歌打开弹框时增加二维码增加songid参数，埋点使用
    },
    log: {
      type: String,
      default: '',
    },
    fromType: {
      type: String,
      default: '',
    },
    logInfo: {
      type: Object,
      default: () => {},
    },
    closeCallback: {
      type: Function,
      default: null,
    },
  },
  setup(props) {
    const { songid, log, logInfo, closeCallback } = toRefs(props)
    const isLogin = computed(() => !!store.state.userInfo.unionid)
    const { getQRCodeURL } = useQRCode()
    const root = ref(null)
    let qrCodeURL = ref(
      'https://qncweb.ktvsky.com/20211130/vadd/82b806b8bf560e49df69162835879c65.png'
    )

    const userType = computed(() => store.state.userInfo.userType)
    const { showRetainModal, getRetainModalTag, setRetainModalTag } =
      useRetainModal()

    const getVipQrcode = async () => {
      // console.log('vipQRcode:', songid.value)
      const { data } = await getCarplayInfo()
      if (get(data, 'pay_qr', '')) {
        const logName = log.value ? log.value : '其他'
        let logEvent = null
        if (payLogFrom.has(logName)) {
          logEvent = payLogFrom.get(logName).m
        } else if (vipLogFrom.has(logName)) {
          logEvent = vipLogFrom.get(logName)
        }
        console.log('PAY LOG:', logEvent, log.value)
        const qrCodeData = await getQRCodeURL(
          `${data.pay_qr}&songid=${
            songid.value ? songid.value : ''
          }&log=${logEvent}&ut=${userType.value}`
        )
        if (qrCodeData) {
          qrCodeURL.value = qrCodeData
        }
        if (songid.value) {
          console.log('点歌登录')
          sendLog({
            event_type: '10000~50000',
            event_name: 30171,
            event_data: {
              str1: '已登录点VIP歌（非VIP）',
              str2: '弹窗',
              str3: '二维码展示',
              str4: 'show',
            },
          })
          if (logInfo.value?.event_name) {
            sendLog({
              event_type: '10000~50000',
              event_name: logInfo.value.event_name,
              event_data: logInfo.value.event_data,
            })
          }
        }
        return
      }
      Toast('未获取到登录二维码')
    }

    const handleCloseModal = () => {
      // 手动关闭时统一做一次用户信息更新 处理用户在个人中心模块-前往开通时，关闭弹窗刷新用户会员信息
      store.dispatch('getCarplayInfo')
      root.value.hide()
      console.log('handleCloseLogin 1')
      // eventBus.emit('handle-close-vip-qrcode')
      if (log.value !== '开屏弹窗2') {
        if (!getRetainModalTag()) {
          showRetainModal()
          setRetainModalTag()
        }
      }
      sendLog({
        event_type: '10000~50000',
        event_name: 10094,
        event_data: {
          str1: '任意页',
          str2: 'VIP弹窗',
          str3: '关闭弹窗',
          str4: 'click',
        },
      })
      closeCallback.value && closeCallback.value()
    }

    const handleCancel = () => {
      // 点击其他区域关闭弹窗时，也去刷一次
      store.dispatch('getCarplayInfo')
      sendLog({
        event_type: '10000~50000',
        event_name: 10094,
        event_data: {
          str1: '任意页',
          str2: 'VIP弹窗',
          str3: '关闭弹窗',
          str4: 'click',
        },
      })
      closeCallback.value && closeCallback.value()
    }

    onBeforeMount(getVipQrcode)

    return {
      qrCodeURL,
      root,
      isLogin,
      handleCancel,
      handleCloseModal,
    }
  },
}
</script>
<style lang="stylus" scoped>
.vip-modal
  &-content
    position relative
    width 540px
    height 654px
    padding-top 0 !important
    background url(https://qncweb.ktvsky.com/20240704/vadd/10c7871524c42dc02ac7ee10b79df031.png) no-repeat
    background-size 100% 100%
    background-position center
    @media screen and (max-width 1200px)
      zoom 0.8
    .close
      position absolute
      top 25px
      right 30px
      left unset!important
      width 40px
      height 40px
    .vip-top-img
      width 242px
      height 138px
      position absolute
      top -70px
      left 379px
    .title
      margin 120px 0 47px
      width 1000px
      height 309px
      display flex
      justify-content center
      img
        width 700px
        height 309px
    .qrcode
      position absolute
      bottom 3px !important
      left 50%
      margin-left -70px
      width 140px
      height 140px
      display flex
      justify-content center
      align-items center
      background #FFFFFF
      border-radius 10px
      @media screen and (max-width 1200px)
        bottom 54px !important
      img
        width 140px
        height 140px
        border-radius 5px
      &-txt
        width 226px
        height 49px
        position absolute
        bottom -42px
        left -18px
        background linear-gradient(90deg, #FF3D3D 0%, #FF881A 100%)
        font-size 24px
        color #fff
        text-align center
        line-height 49px
        border-radius 30px
        padding-left 10px
    .tip
      font-size 32px
      text-align center
      display flex
      flex-direction column
      align-items center
      span
        display flex
        align-items center
      img
        width 45px
        height 45px
        margin-right 10px
    &.vipHighQuality
      background-image url(https://qncweb.ktvsky.com/20240704/vadd/10c7871524c42dc02ac7ee10b79df031.png)!important
      .close
        right 40px
    &.newSongRecommond
      background-image url(https://qncweb.ktvsky.com/20240325/vadd/be5340d5b16723d6d55edad3b24e6fe4.png)!important
      .close
        right 40px
    &.climax
      width 1000px
      height 828px
      background-image url(https://qncweb.ktvsky.com/20240415/other/b4dd3c59771890e2c4e988e014445542.png)!important
      .close
        top 120px
      .qrcode
        margin-bottom 25px
        width 210px
        height 210px
        background none
        border-radius 5px
        @media screen and (max-width: 1200px)
          margin-bottom 10px
</style>
