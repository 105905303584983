import "./styles/init.styl";
import "./styles/global.styl";
import VueLoading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import SearchBar from "@/components/search-bar/index.vue";
import SearchBarVer3 from "@/components/search-bar/index-version3.vue";
import LoginConfrimGlobalModal from "@/components/modal/global/login-confirm";
import LoginQrcodeGlobalModal from "@/components/modal/global/login-qrcode";
import VipQrcodeGlobalModal from "@/components/modal/global/vip-qrcode";
import XifenQrcodeGlobalModal from "@/components/modal/global/xifen-qrcode";
import ActivityGlobalModal from "@/components/modal/global/activity-modal";
import EffectAdjustModal from "@/components/modal/global/effect-adjust";
import MobileOrderQrcodeModal from "@/components/modal/global/mobile-order-qrcode";
import UserCollectModal from "@/components/modal/global/user-collect";
import RecommendModal from "@/components/modal/global/recommend";
import singNotify from "@/components/notify";
import { Toast } from "vant";
import HeadBar from "@/components/head-bar/index.vue";
import VantStyle from "@/components/style/vant.vue";
import LoadMore from "@/components/load-more/index.vue";
import VueImgFallback from "@/directives/img-fallback";
import VueLog from "@/directives/v-log";
import InputFocus from "@/directives/input-focus";
import SourcePreloader from "@/packages/source-preloader";
import { initBootId, initSessionID } from "@/utils/boot";
import { setTag } from "@/utils/bl";
import Vue3TouchEvents from "vue3-touch-events";

const isOnlineDebug = window.location.href.includes("debug");
const isStageDebug = window.location.href.includes("tsl.stage");
const _boot_id = initBootId();
initSessionID();
setTag(_boot_id);

if (isOnlineDebug || isStageDebug) {
  import("@/utils/debug");
}

new SourcePreloader({
  sources: [
    // 歌手占位图
    "https://qncweb.ktvsky.com/20231226/vadd/a3056a76c9626aa4d68318625ae87a35.png",
    // 歌单占位图
    "https://qncweb.ktvsky.com/20211216/vadd/c6d01bc5aa27699dc276fbeab70ecfe5.png",
    // mv封面
    "https://qncweb.ktvsky.com/20211216/vadd/b1e6cb49a76d753245ec0a5dd71356dc.png",
  ],
});

const app = createApp(App);
app.component("SearchBar", SearchBar);
app.component("SearchBarVer3", SearchBarVer3);
app.component("HeadBar", HeadBar);
app.component("VantStyle", VantStyle);
app.component("LoadMore", LoadMore);
app
  .directive("Log", VueLog)
  .directive("ImgFallback", VueImgFallback)
  .directive("Focus", InputFocus)
  .use(LoginConfrimGlobalModal)
  .use(LoginQrcodeGlobalModal)
  .use(VipQrcodeGlobalModal)
  .use(ActivityGlobalModal)
  .use(EffectAdjustModal)
  .use(MobileOrderQrcodeModal)
  .use(UserCollectModal)
  .use(RecommendModal)
  .use(XifenQrcodeGlobalModal)
  .use(singNotify)
  .use(VueLoading)
  .use(Vue3TouchEvents)
  .use(Toast)
  .use(store)
  .use(router)
  .mount("#app");

const pollingWorker = new Worker("./pollingWorker.js", { type: "module" });

pollingWorker.postMessage(_boot_id);
