<template>
  <div class="search-bar">
    <div
      class="search-bar-left"
      :class="{ 'search-bar-left-space': !isShowRight }"
    >
      <template v-if="isShowBack">
        <div class="search-bar-left-back">
          <div class="back" @click="handleBack">
            <img
              class="back-icon"
              src="https://qncweb.ktvsky.com/20231207/vadd/72ff0b114ee2cb3153ce901af19bc813.png"
            />
          </div>
          <span v-if="title" class="title">{{ title }}</span>
        </div>
      </template>

      <!-- <img
        v-else
        class="search-bar-logo"
        src="https://qncweb.ktvsky.com/20231222/other/587b94b627ce3bb928c3b16a26225698.png"
      /> -->

      <div class="search-bar-left-right">
        <div
          v-if="!!placeholder || isSearch"
          class="search-bar-tool"
          :style="customStyle"
          :class="{ 'search-input': isShowSearchBtn }"
        >
          <img
            class="search-bar-tool-icon"
            src="https://qncweb.ktvsky.com/20231205/vadd/e24e8b0fd8b2ba5f24df9b86ba0bc9ef.png"
          />
          <form
            @submit.prevent="handleFormSubmit"
            action="javascript:return true"
          >
            <input
              type="text"
              :placeholder="isShowSearchTip ? '' : placeholder"
              v-model="keyword"
              class="search-bar-tool-input"
              :disabled="!isSearch"
              v-focus
              ref="searchInputDom"
              @keydown="handleSearchKeydown($event)"
              @keyup="handleSearchKeyup($event)"
            />
            <div v-if="isShowSearchTip" class="tip" @click="handleFocusInput">
              限时<span>0.2元/天</span>全曲库任意唱
            </div>
          </form>
          <div
            v-show="!isSearch"
            class="search-bar-tool-mask"
            @click="handleToSearch"
          ></div>
        </div>
        <div class="search-btn" @click="handleSearch" v-if="isShowSearchBtn">
          搜索
        </div>
      </div>
    </div>
    <div class="search-bar-right" v-if="isShowRight">
      <div v-show="!isLogin" @click="handleShowFreeVip" class="search-noLogin">
        <img
          src="https://qncweb.ktvsky.com/20240607/vadd/3fd678db134f6713ea339ccfd318f47a.png"
        />
      </div>
      <div
        v-show="isLogin && !isVip"
        class="search-noVip"
        @click="handleShowVip"
      >
        <img
          class="search-noVip-tit"
          src="https://qncweb.ktvsky.com/20240529/vadd/834653a4b183d29dc8ae446ed421ae3f.png"
        />
        <img
          class="search-noVip-price"
          src="https://qncweb.ktvsky.com/20240529/vadd/aa77f5b1532c7021f940419af5c178ba.png"
        />
      </div>
      <div v-show="isLogin && isVip" class="search-vip" @click="handleShowVip">
        <img
          class="search-vip-tit"
          src="https://qncweb.ktvsky.com/20240529/vadd/93a11f045f72c2da62ffe0d64ae18be5.png"
        />
        <img
          class="search-vip-price"
          src="https://qncweb.ktvsky.com/20240529/vadd/aa77f5b1532c7021f940419af5c178ba.png"
        />
        <img
          class="search-vip-feedback"
          src="https://qncweb.ktvsky.com/20240529/vadd/9f7e763303baee8e48e8a6217ec850f2.png"
        />
      </div>
      <div
        v-if="route_page !== 'singing'"
        class="already-order"
        @click="handleGoSinging"
      >
        <img
          src="https://qncweb.ktvsky.com/20240425/vadd/7c424e9f66c4c05a06d6b5c461e7b9f1.png"
        />
        <p>K歌记录</p>
      </div>
      <div
        v-if="isShowMvEnter"
        class="mv-enter"
        :class="[isSinging && 'mv-enter-active']"
        @click="handleOpenMv"
      >
        <img
          class="mv-enter-icon"
          src="https://qncweb.ktvsky.com/20231214/vadd/98c6a6bf54f29047205204ae050833ad.png"
        />
        <p>进入欢唱</p>
      </div>
      <div v-if="isShowOrderToast" class="order-tips">请先点播歌曲</div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
// import Toast from '@/utils/toast'
import useSongItem from "@/composables/useSongItem";
import useLoginValid from "@/composables/useLoginValid";
import eventBus from "@/utils/event-bus";
import useVip from "@/composables/useVip";
import { sendLog } from "@/directives/v-log/log";
import { CountDown } from "vant";

export default {
  name: "SearchBar",
  components: {
    [CountDown.name]: CountDown,
  },
  props: {
    placeholder: String,
    isImmediately: Boolean,
    isSearch: Boolean,
    isShowBack: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "",
    },
    isShowOrder: {
      type: Boolean,
      default: true,
    },
    isShowMvEnter: {
      type: Boolean,
      default: true,
    },
    isShowSingTaskEnter: {
      type: Boolean,
      default: false,
    },
    // isShowVipActEnter: {
    //   type: Boolean,
    //   default: true,
    // },
    customStyle: {
      type: Object,
      default: () => ({}),
    },
    defaultTab: {
      type: String,
      default: "song", // 默认值，可以是'song'或'singer'
    },
    isShowRight: {
      type: Boolean,
      default: true,
    },
    isShowSearchBtn: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleFocusInput() {
      const inputDom = this.$refs.searchInputDom;
      inputDom.focus();
    },
    handleClearInput() {
      const inputDom = this.$refs.searchInputDom;
      inputDom.value = "";
      this.keyword = "";
    },
  },
  setup(props, { emit }) {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const { orderSong } = useSongItem();
    const { showLoginQrcode, isLogin } = useLoginValid();
    const { showVipQrcode } = useVip();
    const timeData = ref({});
    // 行为埋点 str1 event_name(二维码展示、支付码展示)
    const logEvents = {
      home: ["首页", 30118, 30119],
      search: ["搜索页/结果页", 30122, 30123],
      singing: ["唱过的歌页", 30126, 30127],
      singer: ["歌星列表页", 30130, 30131],
      songList: ["歌星详情页", 30134, 30135],
      mine: ["我的页", "", 30138],
      profile: ["会员中心页", "", 30140],
    };

    // 搜索页和歌星页的来源埋点
    const frObj = {
      search: 1764,
      singer: 1766,
      songList: 1817,
    };
    const route_page = computed(() => route.name);

    // const videoPaused = computed(() => store.state.videoPaused)
    let videoPlayerHistory = computed(() => store.state.videoPlayerHistory);
    const orderedSongIdMap = computed(() => store.state.orderedSongIdMap);
    const initControlPlay = computed(() => store.state.videoInitAutoPlay);
    const orderedList = computed(() => store.state.orderedList);
    const orderedSongNum = computed(() => store.state.orderedList.length);

    const isSingStatus = computed(() => store.state.isSingStatus);
    const isSinging = computed(
      () =>
        isSingStatus.value && orderedSongNum.value && !store.state.videoPaused
    );
    const isShowSearchTip = computed(
      () => !keyword.value && !isInputNotEmpty.value && route.name === "search"
    );
    const isVip = computed(() => !!store.state.vipInfo.end_time);
    const countdownTime = ref(5 * 60 * 1000);
    const preferentialTime = ref(5 * 60 * 1000);
    const freeVipNumber = computed(() => Number(store.state.freeVipNumber));
    const userType = computed(() => store.state.userInfo.userType);
    // 显示歌名 - 歌手 （未播放：显示第一首 正在播放：显示当前这一首以及下一首）
    // const currentOrderSong = computed(() => {
    //   if (!orderedSongNum.value) return ''
    //   if (!isSingStatus.value) {
    //     return `${orderedList.value[0].music_name} - ${orderedList.value[0].singer}`
    //   }
    //   if (orderedSongNum.value > 1) {
    //     return `正在播放:${orderedList.value[0].music_name} - ${orderedList.value[0].singer}   下一首:${orderedList.value[1].music_name} - ${orderedList.value[1].singer}`
    //   }
    //   return `正在播放:${orderedList.value[0].music_name} - ${orderedList.value[0].singer}   下一首:没有歌啦，快去点歌吧~`
    // })

    // 跑马灯内容宽度
    // const scrollTextWidth = computed(() => currentOrderSong.value.length * 24)
    let keyword = ref("");

    let isShowOrderToast = ref(false);
    let showOrderToastTimer = null;
    const isInputNotEmpty = ref(false);

    // const handleTimeFinish = ()=>{
    //   eventBus.emit('handle-time-finish')
    // }
    const handleBack = () => {
      router.back();
    };

    const showOrderToast = () => {
      isShowOrderToast.value = true;
      showOrderToastTimer = setTimeout(() => {
        isShowOrderToast.value = false;
      }, 3 * 1000);
    };
    const handleGoSinging = () => {
      store.dispatch("vipAddSong/resetSong");
      if (!isLogin.value) {
        sendLog({
          event_type: "10000~50000",
          event_name: 30182,
          event_data: {
            str1: "首页",
            str2: "唱过的歌",
            str3: "未登录二维码展示",
            str4: "show",
          },
        });
        showLoginQrcode({
          log: "未登录-唱过的歌",
          from: "singing",
        });
        return;
      }
      router.push({
        name: "singing",
      });
      sendLog({
        event_type: "10000~50000",
        event_name: 30176,
        event_data: {
          str1: "唱过的歌页",
          str2: "唱过的歌页",
          str3: "进入唱过的歌页",
          str4: "show",
        },
      });
    };

    // 只有这里可以进mv页
    const handleOpenMv = () => {
      if (
        !freeVipNumber.value &&
        !isVip.value &&
        orderedList.value[0]?.is_vip
      ) {
        showVipQrcode({});
        return;
      }
      sendLog({
        event_type: "10000~50000",
        event_name: 10066,
        event_data: {
          str1: "首页",
          str2: "进入欢唱",
          str3: "点击进入欢唱",
          str4: "click",
        },
      });
      if (
        Object.keys(orderedSongIdMap.value).length &&
        !initControlPlay.value
      ) {
        orderSong(orderedList.value[0], {
          position: "recovery",
          isPushOrdered: false,
          enabledMvShow: true,
          useFirstSongAutoPlay: true,
        });
        store.commit("UPDATE_IS_SING_STATUS", true);
        store.dispatch("searchTips/updateIsShowSingTips", false); // 关闭tips弹窗
        return;
      }
      if (!videoPlayerHistory.value.songItem.songid) {
        if (isLogin.value) {
          // Toast('请点播歌曲')
          if (isShowOrderToast.value) clearTimeout(showOrderToastTimer);
          showOrderToast();
        } else {
          showLoginQrcode();
        }
        return;
      }
      orderSong(videoPlayerHistory.value.songItem, {
        position: "recovery",
        isPushOrdered: false,
        enabledMvShow: true,
        useFirstSongAutoPlay: true,
      });
      store.commit("UPDATE_IS_SING_STATUS", true);
      store.dispatch("searchTips/updateIsShowSingTips", false); // 关闭tips弹窗
    };
    const handleToSearch = () => {
      sendLog({
        event_type: "10000~50000",
        event_name: 10003,
        event_data: {
          str1: "首页",
          str2: "搜索栏",
          str3: "进入搜索",
          str4: "click",
        },
      });
      router.push({
        name: "search",
        query: { defaultTab: props.defaultTab },
      });
    };

    const handleClearKeyword = () => {
      keyword.value = "";
    };

    const handleChangeKeyword = (e) => {
      keyword.value = e;
    };

    const handleFormSubmit = () => {
      return false;
    };

    const handleSearch = () => {
      emit("search", keyword.value);
    };

    // watch(keyword, (newVal, oldVal) => {
    //   // console.log('keyword', newVal, oldVal)
    //   if (!newVal) {
    //     emit('search', '')
    //   }
    // })

    const handleSearchKeydown = (e) => {
      if (e.keyCode == 13) {
        handleSearch();
      }
    };
    const handleSearchKeyup = (e) => {
      isInputNotEmpty.value = !!e.target.value;
    };

    // const handleOpenOrderControl = () => {
    //   eventBus.emit('show-order-song-control-popup')
    //   sendLog({
    //     event_type: '10000~50000',
    //     event_name: 10005,
    //     event_data: {
    //       str1: '首页',
    //       str2: '已点',
    //       str3: '打开已点',
    //       str4: 'click',
    //     },
    //   })
    // }
    const handleShowFreeVip = () => {
      sendLog({
        event_type: "10000~50000",
        event_name: 30224,
        event_data: {
          str1: "通用",
          str2: "权益顶部运营位",
          str3: "点击",
          str4: "click",
          str5: isLogin.value ? "已登录" : "未登录",
          str7: userType.value,
        },
      });
      showLoginQrcode();
    };
    const handleShowVip = () => {
      sendLog({
        event_type: "10000~50000",
        event_name: 30224,
        event_data: {
          str1: "通用",
          str2: "权益顶部运营位",
          str3: "点击",
          str4: "click",
          str5: isLogin.value ? "已登录" : "未登录",
          str7: userType.value,
        },
      });
      store.dispatch("vipAddSong/resetSong");
      sendLog({
        event_type: "custom",
        event_name: 1733,
      });
      showVipQrcode({
        log: frObj[route.name] ? `${route.name}-顶部运营位` : "首页-顶部运营位",
        isLogin: isLogin.value,
        fr: frObj[route.name] ? frObj[route.name] : 1758,
      });
      if (logEvents[route.name]) {
        if (isLogin.value) {
          sendLog({
            event_type: "10000~50000",
            event_name: logEvents[route.name][2],
            event_data: {
              str1: logEvents[route.name][0],
              str2: "顶部运营位",
              str3: "支付码展示",
              str4: "show",
            },
          });
        } else {
          sendLog({
            event_type: "10000~50000",
            event_name: logEvents[route.name][1],
            event_data: {
              str1: logEvents[route.name][0],
              str2: "顶部运营位",
              str3: "二维码展示",
              str4: "show",
            },
          });
        }
      }
    };

    const resetValue = () => {
      keyword.value = "";
      isInputNotEmpty.value = false;
    };

    onMounted(() => {
      sendLog({
        event_type: "10000~50000",
        event_name: 30223,
        event_data: {
          str1: "通用",
          str2: "权益顶部运营位",
          str3: "展示",
          str4: "show",
          str5: isLogin.value ? "已登录" : "未登录",
          str7: userType.value,
        },
      });
    });

    return {
      keyword,
      route_page,
      // videoPaused,
      // currentOrderSong,
      // scrollTextWidth,
      // orderedSongNum,
      orderedList,
      isShowOrderToast,
      isSinging,
      handleBack,
      handleGoSinging,
      handleOpenMv,
      handleToSearch,
      handleSearch,
      handleChangeKeyword,
      handleClearKeyword,
      handleSearchKeydown,
      handleFormSubmit,
      // handleOpenOrderControl,
      handleShowVip,
      isShowSearchTip,
      handleSearchKeyup,
      resetValue,
      isLogin,
      isVip,
      // countdownTime,
      // timeData,
      preferentialTime,
      handleShowFreeVip,
    };
  },
};
</script>

<style lang="stylus" scoped>
  .search-bar
    display flex
    align-items center
    justify-content space-between
    background rgba(0, 0, 0, 1)
    position fixed
    top 0
    left 0
    width 100vw
    z-index 6
    padding 30px 80px
    @media screen and (max-width 1200px)
      padding 0px 60px 0px 48px
      height 140px
    &-right
      display flex
      align-items center
      .operate-position
        width 308px
        height 84px
        margin-right 40px
        background-image linear-gradient(101deg, rgba(249, 219, 184, 0.29) 0%, rgba(249, 219, 184, 0.29) 41%, rgba(218, 173, 119, 0.28) 100%)
        background url('https://qncweb.ktvsky.com/20230926/vadd/7ad8f2819d894e6990c045311626b55a.png') no-repeat  // https://qncweb.ktvsky.com/20230825/vadd/f348a2d9f739ca514dcf88bc6f87131b.png
        background-size 100% 100%
        background-position center
        @media screen and (max-width 1200px)
          width 166px
          height 64px
          margin-right 0
          background-image linear-gradient(101deg, rgba(249, 219, 184, 0.29) 0%, rgba(249, 219, 184, 0.29) 41%, rgba(218, 173, 119, 0.28) 100%)
          background url('https://qncweb.ktvsky.com/20230825/vadd/d503ffbc40dd05ba48d6ff53d928e573.png') no-repeat
          background-size 100% 100%
          background-position center
      .operate-position-search
        width 135px
        height 77px
        background url('https://qncweb.ktvsky.com/20231213/vadd/8ed4b42692fe01c465ecafb1a51e4c07.png') no-repeat
        background-size 100% 100%
        @media screen and (max-width 1200px)
          width 135px
          height 77px
          background url('https://qncweb.ktvsky.com/20231213/vadd/8ed4b42692fe01c465ecafb1a51e4c07.png') no-repeat
          background-size 100% 100%
      .operate-position-singer
        width 116px
        height 90px
        background url('https://qncweb.ktvsky.com/20231213/vadd/6620460cd5388a35ea813ff178bd1e54.png') no-repeat
        background-size 100% 100%
        @media screen and (max-width 1200px)
          width 116px
          height 90px
          background url('https://qncweb.ktvsky.com/20231213/vadd/6620460cd5388a35ea813ff178bd1e54.png') no-repeat
          background-size 100% 100%
      .already-order
        width 228px
        height 92px
        border-radius 24px
        // border 2px solid rgba(255, 255, 255, 0.20)
        // backdrop-filter blur(100px)
        background-color #FFFFFF1A
        margin-right 32px
        display flex
        align-items center
        justify-content center

        span
          position absolute
          top 10px
          right 32px
          display flex
          justify-content center
          align-items center
          width 32px
          height 32px
          background #E3AB5D
          border-radius 50%
          color #000
          font-size 20px
          font-weight 400
          z-index 1
        &-span
          width 54px !important
          border-radius 18px !important
        img
          width 40px
          height 40px
        p
          width auto
          height 92px
          line-height 92px
          opacity 0.8
          font-weight 400
          font-size 28px
          color #FFFFFF
          margin-left 12px
        @media screen and (max-width 1200px)
          margin 0 20px 0 20px
          width 178px
          height 72px
          p
            font-size 22px
          span
            width 28px
            height 28px
            font-size 18px
            top 7px
            right 10px
          img
            width 30px
            height 30px
      .search-noLogin
        width 320px
        height 96px
        margin-right 30px
        display flex
        align-items center
        //background url('https://qncweb.ktvsky.com/20240529/vadd/e4ab0d67a1cd0b29fe67399560001375.png') no-repeat
        //background-size 100% 100%
        //justify-content center
        @media screen and (max-width 1200px)
          margin-right -100px!important
          margin-left 10px!important
        img
          margin-bottom 20px
          @media screen and (max-width 1200px)
            zoom 0.7
      .search-noVip
        width 380px
        height 107px
        position relative
        margin 0 30px 10px 0
        background url('https://qncweb.ktvsky.com/20240529/vadd/59c418e83fe1fd824161a75abfb9e6f9.png') no-repeat
        background-size 100% 100%
        @media screen and (max-width 1200px)
          width 200px
          height 85px
          margin-right -10px!important
          margin-left 10px!important
        &-tit
          position absolute
          top 35px
          left 22px
          @media screen and (max-width 1200px)
            top 65px
            zoom 0.5
        &-price
          position absolute
          top 70px
          left 23px
          @media screen and (max-width 1200px)
            top 105px
            zoom 0.5
      .search-vip
        width 380px
        height 110px
        position relative
        margin 0 30px 15px 0
        background url('https://qncweb.ktvsky.com/20240529/vadd/fb4644aa3eb6eff1c55ffd46c54b6ce6.png') no-repeat
        background-size 100% 100%
        @media screen and (max-width 1200px)
          width 200px
          height 85px
          margin-right -10px!important
          margin-left 10px!important
        &-tit
          position absolute
          top 35px
          left 22px
          @media screen and (max-width 1200px)
            top 65px
            zoom 0.5
        &-price
          position absolute
          top 70px
          left 23px
          @media screen and (max-width 1200px)
            top 105px
            zoom 0.5
        &-feedback
          position absolute
          top 14px
          right 0
          @media screen and (max-width 1200px)
            top 18px
            zoom 0.6
      .search-countdown
        position absolute
        top 70px
        right 15px
        @media screen and (max-width 1200px)
          zoom 0.6
          top 90px
        .colon
          display inline-block
          margin 0 4px
          color rgba(11, 104, 81, 1)
        .block
          display inline-block
          width 30px
          height 30px
          border-radius 4px
          text-align center
          line-height 32px
          color rgba(11, 104, 81, 1)
          font-size 20px
          font-weight 800
          background rgba(109, 196, 175, 1)
      .order-bottom
        position fixed
        bottom 8px
        right -16px!important
        background rgba(255, 255, 255, 0.08)!important
        border-radius 10px!important
        z-index 10
        @media screen and (max-width 1200px)
          right 6px!important
          bottom 17px
    &-left
      display flex
      align-items center
      flex 1
      .search-bar-left-back
        display flex
        align-items center
      .search-bar-left-right
        display flex
        align-items center
    .search-bar-left-space
      justify-content space-between
    &-logo
        width 229px
        height 47px
        margin-right 60px
        @media screen and (max-width 1200px)
          margin-right 30px
          width 190px
          height auto
        &-icon
          width 100%
          height 39.33px
    &-tool
      position relative
      width 520px
      height 92px
      // border 2px solid rgba(255, 255, 255, 0.20)
      border none
      background rgba(255,255,255,0.08)
      border-radius 24px
      display flex
      align-items center
      @media screen and (max-width 1200px)
        width 270px
        height 72px
      &-mask
        position absolute
        top 0
        right 0
        left 0
        bottom 0
        width 100%
        height 100%
      &-icon
        width 27px
        height 27px
        margin 0 18px 0 31px
      form
        width calc(100% - 90px)
        position relative
        .tip
          width 100%
          height 100%
          position absolute
          top 0
          left 3px
          color #FFFFFF66
          span
            color #DBAE6A
            margin 0 5px
      &-input
        display flex
        align-items center
        width 100% !important
        height 40px
        font-size 28px
        font-weight 500
        color rgba(255, 255, 255, .8)
        @media screen and (max-width 1200px)
          font-size 22px
  .search-input
    width 520px
    margin-left 0px
    margin-right 40px
    @media screen and (max-width 1200px)
      width 270px
      height 72px
      margin-right 20px
  .back
    width fit-content
    height 90px
    display flex
    align-items center
    justify-content center
    img
      width 40px
      height 40px
      margin-right 60px
    @media screen and (max-width 1200px)
      height 72px
      img
        width 32px
        height 32px
        margin-right 48px
  .title
    color rgba(255, 255, 255, 1)
    font-size 32px
    font-weight 300
    width auto
    height 90px
    line-height 90px
    white-space nowrap
    overflow hidden
    text-overflow ellipsis
    @media screen and (max-width 1200px)
      height 72px
      line-height 72px
      font-size 26px
  .search-btn
    display flex
    justify-content center
    align-items center
    width 200px
    height 92px
    border-radius 4px
    background #DBAE6A
    backdrop-filter blur(100px)
    color rgba(0, 0, 0, 0.8)
    font-size 28px
    @media screen and (max-width 1200px)
      width 160px
      height 72px
      font-size 22px
  .current-order-song
    width 300px
    height 80px
    padding 0 30px
    background #1E1F21
    border-radius 100px
    margin-right 40px
    display flex
    align-items center
    justify-content center
    overflow hidden
    position relative
    &-unactive
      font-size 24px
      color rgba(255, 255, 255, 0.20) !important
    &-shadow
      position absolute
      top 0
      right 30px
    @media screen and (max-width 1200px)
      margin 0 20px 0 20px
    &-scroll
      width 238px
      height 80px
      overflow hidden
      &-inner
        width auto
        height 80px
        background #1E1F21
        display flex
        align-items center
        font-weight 400
        font-size 24px
        white-space nowrap
        color rgba(255, 255, 255, 0.40)
        animation scroll 10s linear 0s infinite
        img
          width 26px
          height 26px
          margin-right 10px
  .mv-enter
    width 228px
    height 92px
    border-radius 24px
    // border 2px solid rgba(255, 255, 255, 0.20)
    // backdrop-filter blur(100px)
    background-color #FFFFFF1A
    color rgba(255, 255, 255, 0.8)
    justify-self flex-end
    font-size 28px
    display flex
    align-items center
    position relative
    &-icon
      width 40px
      height 40px
      margin 0 12px 0 32px
    @media screen and (max-width 1200px)
      width 178px
      height 72px
      font-size 22px
      &-icon
        width 32px
        height 32px
        margin 0 10px 0 24px
  .mv-enter-active
    border-radius 6px
    background url('https://qncweb.ktvsky.com/20231222/other/77557d64f9dbb5af8980786ee9216b9f.png') no-repeat // 等动效图
    background-size 100% 100%
  .order-tips
    width 228px
    height 99px
    padding-top 36px
    color #fff
    font-weight 400
    font-size 26px
    display flex
    justify-content center
    position absolute
    top 140px
    right 80px
    background url('https://qncweb.ktvsky.com/20231208/vadd/194a6ef02c0b3a0b936f727e714de20e.png') no-repeat
    background-size 100% 100%
    @media screen and (max-width 1200px)
      zoom calc(180 / 228)
  .already-order-history img
    width 40px
    height 40px
    margin 0 12px 0 32px
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-70%);
  }
}
</style>
