<template>
  <CommonModal ref="root" :onCancel="handleCancel">
    <div class="aiface-modal-content">
      <div @click="handleCloseModal" class="close"></div>
      <div class="qrcode">
        <img :src="AIQrcode" />
      </div>
      <div class="cancel" @click="handleClickCancel"></div>
    </div>
  </CommonModal>
</template>
<script>
import { onBeforeMount, ref, toRefs, computed, watch } from "vue";
import store from "@/store";
import CommonModal from "@/components/modal/common/component.vue";
import useQRCode from "@/composables/useQRCode";
import { sendLog } from "@/directives/v-log/log";

export default {
  name: "AIFaceModal",
  components: {
    CommonModal,
  },
  props: {
    cancelEvent: {
      type: Function,
      default: () => {},
    },
    fromType: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const { fromType } = toRefs(props);
    const { getQRCodeURL } = useQRCode();
    const root = ref(null);
    let AIQrcode = ref(
      "https://qncweb.ktvsky.com/20211130/vadd/82b806b8bf560e49df69162835879c65.png"
    );

    const aiFaceSong = computed(() => store.state.aiFaceSong);
    const _mac = computed(() => store.state.macAddress);
    const aiFaceQr = computed(() => store.state.aiFaceQr);
    const userType = computed(() => store.state.userInfo.userType);

    const getQrcode = async () => {
      console.log("aiFaceQr", aiFaceQr.value);
      AIQrcode.value = await getQRCodeURL(
        `${aiFaceQr.value}&log=${1887}&ut=${
          userType.value
        }&fromType=tesla_playlist`
      );
      // AIQrcode.value = await getQRCodeURL(`https://m.ktvsky.com/vadd/facechange/mv/p/r?f=${fromType.value}&_src=600114&_mac=${_mac.value}`)
    };

    const handleCloseModal = () => {
      // 手动关闭时统一做一次用户信息更新 处理用户在个人中心模块-前往开通时，关闭弹窗刷新用户会员信息
      store.dispatch("getCarplayInfo");
      root.value.hide();
      sendLog({
        event_type: "10000~50000",
        event_name: 10094,
        event_data: {
          str1: "任意页",
          str2: "VIP弹窗",
          str3: "关闭弹窗",
          str4: "click",
        },
      });
    };

    const handleCancel = () => {
      // 点击其他区域关闭弹窗时，也去刷一次
      store.dispatch("getCarplayInfo");
      sendLog({
        event_type: "10000~50000",
        event_name: 10094,
        event_data: {
          str1: "任意页",
          str2: "VIP弹窗",
          str3: "关闭弹窗",
          str4: "click",
        },
      });
    };

    const handleClickCancel = () => {
      root.value.hide();
      props.cancelEvent.call();
    };

    onBeforeMount(getQrcode);

    watch(aiFaceSong, (newVal) => {
      console.log(newVal, "aiFaceSong");
      if (newVal) {
        root.value.hide();
      }
    });

    return {
      root,
      handleCancel,
      handleCloseModal,
      handleClickCancel,
      AIQrcode,
    };
  },
};
</script>
<style lang="stylus" scoped>
.aiface-modal
  &-content
    position relative
    width 754px
    height 644px
    padding-top 0 !important
    background url(https://qncweb.ktvsky.com/20240428/other/49ca27466526ca58be6ab4e57cd8a1ba.png) no-repeat
    background-size 100% 100%
    background-position center
    margin-left 30px
    .cancel
      color #fff
      position absolute
      bottom 28px
      left 50%
      width 192px
      height 50px
      margin-left -96px
    .close
      position absolute
      top 0px
      right 0px
      left unset!important
      width 66px
      height 66px
    .qrcode
      position absolute
      bottom 99px
      right 156px
      margin-left -86px
      width 168px
      height 168px
      display flex
      justify-content center
      align-items center
      background #fff
      border-radius 10px
      @media screen and (max-width 1200px)
        bottom 100px
      img
        width 160px
        height 160px
        border-radius 5px
      &-txt
        width 226px
        height 49px
        position absolute
        bottom -42px
        left -18px
        background linear-gradient(90deg, #FF3D3D 0%, #FF881A 100%)
        font-size 24px
        color #fff
        text-align center
        line-height 49px
        border-radius 30px
        padding-left 10px
</style>
